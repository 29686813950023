// Routes
const routes: { name: string; path: string; transparentHeader: boolean }[] = [
  { name: "Home", path: "/", transparentHeader: true },
  { name: "About", path: "/about", transparentHeader: false },
  { name: "Work", path: "/work", transparentHeader: false },
  { name: "Blog", path: "/blog", transparentHeader: false },
  { name: "Contact", path: "/contact", transparentHeader: false },
];

export default routes;
