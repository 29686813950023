import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  // :root {

  //   --screen-sm: 640px;
  //   --screen-md: 768px;
  //   --screen-lg: 1024px;
  //   --screen-xl: 1280px;
    
  //   --color-text: var(--color-gray-700);
  //   --color-heading: var(--color-primary);
  //   --color-background: hsl(0, 0%, 100%);
  //   --color-blurred-background: hsla(0, 0%, 100%, 0.85);
  //   --color-primary: hsl(217, 77%, 39%); 
  //   --color-primary-light: hsl(217, 100%, 71%);
  //   --color-secondary: hsl(343, 100%, 53%);
  //   --color-tertiary: hsl(199, 87%, 68%);
  //   --color-decorative: hsl(30, 91%, 56%);
  //   --color-info: hsl(245, 100%, 60%);
  //   --color-muted: hsl(109, 92%, 95%);
  //   --color-muted-background: hsla(210, 55%, 92%, 0.85);
  //   --color-success: hsl(160, 100%, 40%);
  //   --color-success-background: hsla(160, 100%, 40%, 0.1);
  //   --color-error: hsl(340, 95%, 50%);
  //   --color-error-background: hsla(340, 95%, 43%, 0.1);
  //   --color-alert: hsl(37, 100%, 50%);
  //   --color-alert-background: hsla(52, 100%, 50%, 0.25);
  //   --color-venn-0: hsl(190, 100%, 65%);
  //   --color-venn-1: hsl(340, 100%, 65%);
  //   --color-gray-100: hsl(225, 25%, 95%);
  //   --color-gray-200: hsl(225, 16%, 90%);
  //   --color-gray-300: hsl(225, 8%, 80%);
  //   --color-gray-400: hsl(225, 8%, 70%);
  //   --color-gray-500: hsl(225, 7%, 60%);
  //   --color-gray-600: hsl(225, 15%, 50%);
  //   --color-gray-700: hsl(225, 12%, 40%);
  //   --color-gray-900: hsl(225, 25%, 20%);
  //   --color-gray-1000: hsl(225, 15%, 15%);
  //   --color-white: #ffffff;
  //   --color-black: #000000;
  //   --color-subtle-background: hsl(225, 25%, 95%);
  //   --color-subtle-floating: hsl(0, 0%, 100%);
  //   --color-homepage-light: hsl(204, 67%, 85%);
  //   --color-homepage-dark: hsl(202, 71%, 90%);
  //   --color-homepage-bg: hsl(204, 67%, 85%);
  //   --color-footer-background: hsl(0,0%,97%);

  //   --font-size-xs: 1.2rem;
  //   --font-size-sm: 1.4rem;
  //   --font-size-base: 1.6rem;
  //   --font-size-lg: 1.8rem;
  //   --font-size-xl: 2.4rem;
  //   --font-size-2xl: 2.4rem;
  //   --font-size-3xl: 3.0rem;
  //   --font-size-4xl: 3.6rem;
  //   --font-size-5xl: 4.8rem;
  //   --font-size-6xl: 6.0rem;
  //   --font-weight-black: 900;
  //   --font-weight-bold: 700;
  //   --font-weight-medium: 400;
  //   --font-weight-light: 300;
  //   --font-family: "Montserrat",-apple-system,sans-serif;
  //   --font-family-mono: "League Mono","Fira Mono",monospace;

  //   --syntax-bg: hsl(225, 25%, 97%);
  //   --syntax-highlight: hsl(225, 25%, 93%);
  //   --syntax-txt: #2A2A2A;
  //   --syntax-comment: #467790;
  //   --syntax-prop: #da0079;
  //   --syntax-bool: #bf00b8;
  //   --syntax-val: #78909C;
  //   --syntax-str: #651fff;
  //   --syntax-name: #AA00FF;
  //   --syntax-del: rgb(255, 85, 85);
  //   --syntax-regex: #3600d6;
  //   --syntax-fn: #3D5AFE;
    
  //   --prefers-dark: false;
  //   --scrollbar-width: 12px;

  //   --height-footer: 21.2rem;

  // }

  // html {
  //   font-size: 62.5%;
  // }

  // body {
  //   margin: 0;
  //   padding: 0;
  //   background: var(--color-white);
  //   font-size: 1.6rem;
  //   font-family: var(--font-family);
  //   font-weight: var(--font-weight-medium);
  //   line-height: 1.75;
  //   color: var(--color-text);
  // }

  // // fix body when menu is open
  // .fixed-body {
  //   height: 100%;
  //   overflow: hidden;
  // }

  // p + p {margin-top: 1em;}

  // h1, h2, h3, h4, h5 {
  //   margin: 1.229em 0 .58em;
  //   font-weight: var(--font-weight-bold);
  //   line-height: 1.3;
  //   color: var(--color-primary);
  // }

  // h1 {font-size: 4.883rem;}

  // h2 {font-size: 3.906rem;}

  // h3 {font-size: 3.125rem;}

  // h4 {font-size: 2.5rem;}

  // h5 {font-size: 2rem;}

  // small, .text_small {font-size: 0.8rem;}

  
  // ul.custom {
  //   margin: 1rem 0;
  //   padding-inline-start: 0;
  //   li {
  //     margin-left: 2.2rem;
  //     list-style: none;
  //     position: relative;
  //     &::before {
  //       content: "*";
  //       color: var(--color-primary);
  //       position: absolute;
  //       top: 0.3rem;
  //       left: -1.7rem;
  //     }
  //   }
  //   &.checkmark {
  //     li {
  //       &::before {
  //         content: "\\2714";
  //         top: 0rem;
  //         left: -2rem;
  //       }
  //     }
  //   }
  //   &.double-arrow {
  //     li {
  //       &::before {
  //         content: "\\2A20";
  //         top: -0.1rem;
  //         left: -2rem;
  //       }
  //     }
  //   }
  // }

`;

export { GlobalStyles };
