export default {
  screens: {
    // max-widths in pixels
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
  },
  mq: {
    sm: "(min-width: 640px)",
    md: "(min-width: 768px)",
    lg: "(min-width: 1024px)",
    xl: "(min-width: 1280px)",
  },
  // colors: {
  //   primary: "#1753B2",
  //   primarLight: "#6AA4FF",
  //   accent: "#B29559",
  //   accentLight: "#FFECC6",
  //   blue: "#0099ff",
  //   darkblue: "#00072e",
  //   orange: "#FF9429",
  // },
  // opacity: {
  //   0: 0,
  //   25: 0.25,
  //   50: 0.5,
  //   75: 0.75,
  //   100: 1,
  // },
  // py: (value: number | string) => `padding-top: ${value}; padding-bottom: ${value};`,
  // Add anything else you'd like.
};
