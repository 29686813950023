const Button = {
  // 1. We can update the base styles
  baseStyle: {
    fontWeight: "semibold",
    color: "white",
  },
  // 2. We can add a new size or extend existing size
  sizes: {
    xl: {
      h: "56px",
      fontSize: "lg",
      px: "32px",
    },
  },
  // 3. We can add a new visual variant
  variants: {
    "with-shadow": {
      bg: "red.400",
      boxShadow: "0 0 2px 2px #efdfde",
      color: "brand.lightShades",
      _hover: {
        bg: "blue.500",
      },
    },
    // 4. We can override existing variants
    solid: {
      bg: "blue.500",
      _hover: {
        bg: "blue.400",
      },
      _active: {
        bg: "blue.500",
      },
    },
  },
};

export { Button };
