const Text = {
  // 1. We can update the base styles
  baseStyle: {
    lineHeight: 1.75,
  },
  // 2. We can add a new size or extend existing size
  sizes: {},
  // 3. We can add a new visual variant
  variants: {},
};

export { Text };
