import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled, { ThemeProvider } from "styled-components";
import { theme, GlobalStyles } from "../../styles";
import "../../styles/index.scss";

// Components
import { CSSDebugger } from "../css-debugger";
import { Header } from "../Header";
import { Footer } from "../Footer";

const Layout: React.FC<LayoutProps> = ({ children, location }) => {
  return (
    <ThemeProvider theme={theme()}>
      <AnimatePresence>
        <GlobalStyles />
        <Wrapper>
          <Header location={location} />
          {/* <CSSDebugger /> */}
          <Content key={location.pathname} variants={variants} initial="initial" animate="enter" exit="exit">
            {children}
          </Content>
          <div
            css={`
              flex-grow: 1;
            `}
          />
          <Footer />
        </Wrapper>
      </AnimatePresence>
    </ThemeProvider>
  );
};

// Styled Components
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
  main > section:first-child {
    margin-top: 5rem;
  }
`;

const Content = styled(motion.main)`
  display: block;
  width: 100%;
  min-height: 100%;
`;
// Types
type LayoutProps = {
  children: React.ReactNode;
  location?: any;
};

// Framer Motion Variants
const duration = 0.5; // Transition & Delay Duration

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
};

export { Layout };
