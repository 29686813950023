import React from "react";
import { Box, Button, Container, Heading, Input, FormLabel, Box as Section, Select, Text, useToast } from "@chakra-ui/react";

const ResponsiveYouTube = () => {
  const [aspectRatio, setAspectRatio] = React.useState("42.85");
  const [youtubeId, setYoutubeId] = React.useState("AAbzF7lcyeE");
  const [showOutput, setShowOutput] = React.useState(false);
  const toast = useToast();

  const handleSelect = (e) => {
    setShowOutput(false);
    setAspectRatio(e.target.value);
  };
  const handleChange = (e) => {
    setShowOutput(false);
    setYoutubeId(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!youtubeId) {
      return;
    }
    setShowOutput(true);
  };

  const youtubeCode = `<div style="position: relative; padding-bottom: ${aspectRatio}%; height: 0; overflow: hidden;"><iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" src="https://www.YouTube-nocookie.com/embed/${youtubeId}?modestbranding=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe></div>
`;

  return (
    <>
      <Container maxWidth="container.sm" mt={4} mx="auto" p={4} borderWidth={1}>
        <Box>
          <form onSubmit={handleSubmit}>
            <FormLabel _after={{ content: "'*'", color: "red" }}>Aspect Ratio</FormLabel>
            <Select placeholder="Select option" onChange={handleSelect} required my={0}>
              <option value="56.25">16:9</option>
              <option value="75">4:3</option>
              <option value="42.85" selected={true}>
                21:9
              </option>
            </Select>

            <FormLabel mt={4} _after={{ content: "'*'", color: "red" }}>
              YouTube ID
            </FormLabel>
            <Input variant="filled" colorScheme="facebook" type="text" name="name" onChange={handleChange} value={youtubeId} />

            {!showOutput && (
              <Button mt={8} type="submit" colorScheme="facebook">
                Generate
              </Button>
            )}
          </form>
        </Box>
      </Container>
      <Box mt={8}>
        {showOutput && (
          <Box>
            <Box
              dangerouslySetInnerHTML={{
                __html: youtubeCode,
              }}
            />
            {showOutput && (
              <Button
                mt={8}
                colorScheme="facebook"
                w="full"
                onClick={() => {
                  navigator.clipboard.writeText(youtubeCode);
                  toast({
                    title: "Copied to clipboard",
                    description: "",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                  });
                }}
              >
                Copy HTML
              </Button>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

// Styled components

export default ResponsiveYouTube;
