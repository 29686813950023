import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
// Utilities
import routes from "../../utils/routes";
// Components
import { Box, Button, Container, Flex, Heading, Text, Spacer } from "@chakra-ui/react";
import { Link } from "gatsby";
// import { Container } from "../Container";

const MotionBox = motion(Box);

const Header = ({ location }: HeaderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTransparent, setIsTransparent] = useState(true);
  const [transparentHeader, setTransparentHeader] = useState<boolean | undefined>(true);
  // identify if this route has a transparent header
  const path = location.pathname ? location.pathname : "/";
  const currentRoute = routes.find((route) => route.path === path);

  useEffect(() => {
    setTransparentHeader(currentRoute?.transparentHeader);

    const handleScroll = () => {
      let top = document.body.getBoundingClientRect().top;
      if (top >= -50) {
        setIsTransparent(true);
      } else {
        setIsTransparent(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [path]);

  const handleClick = () => {
    setIsOpen((prevValue) => !prevValue);
    const el = document.body.classList.contains("fixed-body");
    if (el) {
      document.body.classList.remove("fixed-body");
    } else {
      document.body.classList.add("fixed-body");
    }
  };

  return (
    <MotionBox
      zIndex="1000"
      height
      width="100%"
      marginX="auto"
      overflow="hidden"
      position="fixed"
      boxShadow={isTransparent && transparentHeader ? "none" : "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)"}
      background={isTransparent && transparentHeader ? "transparent" : "var(--color-white)"}
      sx={{ transition: "all 0.4s linear" }}
    >
      <Container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="50px"
        maxWidth={["container.sm", "container.lg"]}
        width="100%"
      >
        <MotionBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          transparentHeader={transparentHeader}
          isTransparent={isTransparent}
        >
          <Link to="/" style={{ display: "inherit" }}>
            <svg width={100} height={35} viewBox="0 0 500 175">
              <path
                fill={isTransparent && transparentHeader ? "white" : "var(--color-primary)"}
                d="M469.9 121.4c0 2-.2 4.1-.7 6.3-.5 2.2-1.2 4.4-2.2 6.6-1 2.2-2.2 4.3-3.8 6.3-1.5 2-3.4 3.8-5.6 5.3-2.2 1.5-4.7 2.8-7.6 3.7-2.9.9-6.1 1.4-9.8 1.4H402c-2 0-4.1-.2-6.3-.7s-4.4-1.2-6.6-2.2c-2.2-1-4.3-2.2-6.3-3.8-2-1.5-3.8-3.4-5.3-5.6s-2.8-4.7-3.7-7.6c-.9-2.9-1.4-6.2-1.4-9.8V25.9h21.2v95.5c0 2.6.8 4.7 2.4 6.2 1.6 1.5 3.7 2.3 6.1 2.3h38.2c2.7 0 4.7-.8 6.2-2.4 1.5-1.6 2.2-3.6 2.2-6.2V92.7c0-2.7-.8-4.7-2.4-6.2-1.6-1.5-3.6-2.2-6.1-2.2H402V63.1h38.2c2 0 4.1.2 6.3.7 2.2.5 4.4 1.2 6.6 2.2 2.2 1 4.3 2.2 6.3 3.8s3.8 3.4 5.3 5.6 2.7 4.7 3.7 7.6 1.4 6.1 1.4 9.8v28.6zM354.5 140.4c0 1.5-.3 2.9-.8 4.2-.5 1.3-1.3 2.4-2.2 3.4-.9.9-2.1 1.7-3.3 2.2-1.3.5-2.6.8-4.1.8h-47.8c-2.6 0-5.4-.3-8.3-.9-3-.6-5.9-1.6-8.7-2.9-2.9-1.3-5.7-3-8.3-5s-5-4.5-7-7.4c-2-2.9-3.6-6.3-4.8-10-1.2-3.8-1.8-8.1-1.8-12.9V63.1h21.2v48.8c0 2.8.5 5.2 1.4 7.4.9 2.2 2.2 4.1 3.9 5.7 1.6 1.6 3.5 2.8 5.7 3.6 2.2.8 4.6 1.3 7.1 1.3h37V63.1h21.2v77.3zM239 121.4c0 3.6-.5 6.9-1.4 9.8-.9 2.9-2.1 5.5-3.7 7.6s-3.3 4.1-5.3 5.6-4.1 2.8-6.3 3.8-4.4 1.7-6.7 2.2c-2.3.5-4.4.7-6.4.7H171c-3.6 0-6.9-.5-9.8-1.4-2.9-.9-5.4-2.2-7.6-3.7-2.2-1.5-4.1-3.3-5.6-5.3s-2.8-4.1-3.8-6.3-1.7-4.4-2.2-6.7c-.5-2.3-.7-4.4-.7-6.3V92.7c0-5.4 1-10 3-13.8 2-3.8 4.4-6.8 7.4-9.2s6.2-4.1 9.6-5.1c3.5-1 6.7-1.5 9.6-1.5h38.2v21.2h-38c-2.8 0-5 .7-6.4 2.2s-2.2 3.5-2.2 6.3v28.5c0 2.9.7 5 2.2 6.5 1.4 1.5 3.5 2.2 6.3 2.2h38.2c2.9 0 5-.7 6.4-2.2 1.4-1.5 2.1-3.6 2.1-6.3V25.9H239v95.5z"
              />
              <path
                fill={isTransparent && transparentHeader ? "white" : "var(--color-primary-light)"}
                d="M123.6 151.1h-21.2v-48.8c0-2.8-.5-5.2-1.4-7.4-.9-2.2-2.2-4.1-3.9-5.7s-3.5-2.8-5.7-3.6c-2.2-.8-4.6-1.3-7.1-1.3h-37v66.8H26.1V73.6c0-1.5.3-2.8.8-4.1.5-1.3 1.3-2.4 2.3-3.3 1-.9 2.1-1.7 3.4-2.2 1.3-.5 2.7-.8 4.2-.8h47.7c2.7 0 5.5.3 8.4.9 3 .6 5.9 1.6 8.8 2.9 2.9 1.3 5.7 3 8.3 5s5 4.5 7 7.4c2 2.9 3.6 6.3 4.8 10 1.2 3.8 1.8 8.1 1.8 12.9v48.8z"
              />
            </svg>
          </Link>
        </MotionBox>
        <Spacer />
        <Box display={["none", "inline-flex"]} alignItems="center" margin={0} padding={0}>
          {routes.map((link, index) => {
            return (
              <Box
                key={index}
                fontSize="sm"
                py={4}
                px={2}
                mb={[4, 0]}
                color={isTransparent && transparentHeader ? "var(--color-gray-100)" : "var(--color-text)"}
                textShadow={isTransparent && transparentHeader ? " 1px 1px 2px var(--color-gray-700)" : "none"}
                textTransform="uppercase"
                fontWeight="medium"
              >
                <Link to={link.path} activeStyle={{ borderStyle: "inset", borderBottom: "1px solid" }}>
                  {link.name}
                </Link>
              </Box>
            );
          })}
        </Box>

        <Box
          display={["flex", "none"]}
          flexDirection="column"
          justifyContent="space-between"
          zIndex="1001"
          height="24px"
          overflow="hidden"
          position="sticky"
          onClick={handleClick}
        >
          <>
            <HamburgerLine
              isOpen={isOpen}
              transparentHeader={transparentHeader}
              isTransparent={isTransparent}
              variants={hamburgerLineVariants}
              animate={isOpen ? "showHamLineOne" : "initial"}
              transition={{ duration: 0.4 }}
            />
            <HamburgerLine
              isOpen={isOpen}
              transparentHeader={transparentHeader}
              isTransparent={isTransparent}
              variants={hamburgerLineVariants}
              animate={isOpen ? "showHamLineTwo" : "initial"}
              transition={{ duration: 0.4 }}
            />
            <HamburgerLine
              isOpen={isOpen}
              transparentHeader={transparentHeader}
              isTransparent={isTransparent}
              variants={hamburgerLineVariants}
              animate={isOpen ? "showHamLineThree" : "initial"}
              transition={{ delay: 0.3, duration: 0.2 }}
            />
          </>
        </Box>
        <AnimatePresence>
          {isOpen && (
            <MotionBox
              key="menu"
              initial={{ y: "100%", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
              exit={{ y: "100%" }}
              display="flex"
              flexDirection="column"
              justifyContent="start"
              alignItems="center"
              background="var(--color-footer-background)"
              position="fixed"
              margin={0}
              paddingTop={56}
              top="0"
              left="0"
              width="100%"
              height="100vh"
              overflow="hidden"
              zIndex="1000"
            >
              <MotionBox
                key="logo"
                initial={{ y: "-500%", opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.8, type: "spring", stiffness: 70 }}
                mb={4}
              >
                <Link to="/" onClick={handleClick}>
                  <svg width={130} viewBox="0 0 500 175">
                    <path
                      fill="var(--color-primary)"
                      d="M469.9 121.4c0 2-.2 4.1-.7 6.3-.5 2.2-1.2 4.4-2.2 6.6-1 2.2-2.2 4.3-3.8 6.3-1.5 2-3.4 3.8-5.6 5.3-2.2 1.5-4.7 2.8-7.6 3.7-2.9.9-6.1 1.4-9.8 1.4H402c-2 0-4.1-.2-6.3-.7s-4.4-1.2-6.6-2.2c-2.2-1-4.3-2.2-6.3-3.8-2-1.5-3.8-3.4-5.3-5.6s-2.8-4.7-3.7-7.6c-.9-2.9-1.4-6.2-1.4-9.8V25.9h21.2v95.5c0 2.6.8 4.7 2.4 6.2 1.6 1.5 3.7 2.3 6.1 2.3h38.2c2.7 0 4.7-.8 6.2-2.4 1.5-1.6 2.2-3.6 2.2-6.2V92.7c0-2.7-.8-4.7-2.4-6.2-1.6-1.5-3.6-2.2-6.1-2.2H402V63.1h38.2c2 0 4.1.2 6.3.7 2.2.5 4.4 1.2 6.6 2.2 2.2 1 4.3 2.2 6.3 3.8s3.8 3.4 5.3 5.6 2.7 4.7 3.7 7.6 1.4 6.1 1.4 9.8v28.6zM354.5 140.4c0 1.5-.3 2.9-.8 4.2-.5 1.3-1.3 2.4-2.2 3.4-.9.9-2.1 1.7-3.3 2.2-1.3.5-2.6.8-4.1.8h-47.8c-2.6 0-5.4-.3-8.3-.9-3-.6-5.9-1.6-8.7-2.9-2.9-1.3-5.7-3-8.3-5s-5-4.5-7-7.4c-2-2.9-3.6-6.3-4.8-10-1.2-3.8-1.8-8.1-1.8-12.9V63.1h21.2v48.8c0 2.8.5 5.2 1.4 7.4.9 2.2 2.2 4.1 3.9 5.7 1.6 1.6 3.5 2.8 5.7 3.6 2.2.8 4.6 1.3 7.1 1.3h37V63.1h21.2v77.3zM239 121.4c0 3.6-.5 6.9-1.4 9.8-.9 2.9-2.1 5.5-3.7 7.6s-3.3 4.1-5.3 5.6-4.1 2.8-6.3 3.8-4.4 1.7-6.7 2.2c-2.3.5-4.4.7-6.4.7H171c-3.6 0-6.9-.5-9.8-1.4-2.9-.9-5.4-2.2-7.6-3.7-2.2-1.5-4.1-3.3-5.6-5.3s-2.8-4.1-3.8-6.3-1.7-4.4-2.2-6.7c-.5-2.3-.7-4.4-.7-6.3V92.7c0-5.4 1-10 3-13.8 2-3.8 4.4-6.8 7.4-9.2s6.2-4.1 9.6-5.1c3.5-1 6.7-1.5 9.6-1.5h38.2v21.2h-38c-2.8 0-5 .7-6.4 2.2s-2.2 3.5-2.2 6.3v28.5c0 2.9.7 5 2.2 6.5 1.4 1.5 3.5 2.2 6.3 2.2h38.2c2.9 0 5-.7 6.4-2.2 1.4-1.5 2.1-3.6 2.1-6.3V25.9H239v95.5z"
                    />
                    <path
                      fill="var(--color-primary-light)"
                      d="M123.6 151.1h-21.2v-48.8c0-2.8-.5-5.2-1.4-7.4-.9-2.2-2.2-4.1-3.9-5.7s-3.5-2.8-5.7-3.6c-2.2-.8-4.6-1.3-7.1-1.3h-37v66.8H26.1V73.6c0-1.5.3-2.8.8-4.1.5-1.3 1.3-2.4 2.3-3.3 1-.9 2.1-1.7 3.4-2.2 1.3-.5 2.7-.8 4.2-.8h47.7c2.7 0 5.5.3 8.4.9 3 .6 5.9 1.6 8.8 2.9 2.9 1.3 5.7 3 8.3 5s5 4.5 7 7.4c2 2.9 3.6 6.3 4.8 10 1.2 3.8 1.8 8.1 1.8 12.9v48.8z"
                    />
                  </svg>
                </Link>
              </MotionBox>
              {routes.map((link, index) => {
                return (
                  <MotionBox
                    key={index}
                    variants={navItemVariants}
                    initial="hidden"
                    animate="show"
                    transition={{ delay: 0.2 + index / 5, duration: 0.2 + index / 10 }}
                    mt={4}
                  >
                    <Box>
                      <Link
                        activeStyle={{ fontWeight: "bold", borderBottom: "1px solid var(--color-primary)", display: "inline-block" }}
                        to={link.path}
                        onClick={handleClick}
                      >
                        <Text fontSize="2xl" color="brand.main">
                          {link.name}
                        </Text>
                      </Link>
                    </Box>
                  </MotionBox>
                );
              })}
            </MotionBox>
          )}
        </AnimatePresence>
      </Container>
    </MotionBox>
  );
};

// Styled Components
const Sections = styled(motion.section)<SectionProps>`
  top: 0px;
  z-index: 1000;
  width: 100%;
  min-height: ${({ minHeight }) => minHeight};
  margin: 0 auto;
  padding: 0;
  padding-top: ${({ paddingTop }) => paddingTop};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  position: ${({ position }) => position};
  overflow: hidden;
  transition: background 0.5s;

  box-shadow: ${({ isTransparent, transparentHeader }) =>
    isTransparent && transparentHeader ? "none" : "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)"};
  background: ${({ isTransparent, transparentHeader }) => (isTransparent && transparentHeader ? "transparent" : "var(--color-white)")};
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 5rem;
`;
const Logo = styled(motion.div)<LogoProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .nStyle {
    fill: ${({ isTransparent, transparentHeader }) => (isTransparent && transparentHeader ? "var(--color-white)" : "var(--color-primary)")};
  }
  .dubStyle {
    fill: ${({ isTransparent, transparentHeader }) =>
      isTransparent && transparentHeader ? "var(--color-white)" : "var(--color-primary-light)"};
  }
`;
const NavBar = styled.div`
  display: none;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;

  @media ${({ theme }) => theme.mq.sm} {
    display: inline-flex;
  }
`;
const NavItem = styled(motion.li)`
  font-size: 2.4rem;
  font-weight: var(-bold);
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  &:last-child {
    margin-right: 0;
  }

  @media ${({ theme }) => theme.mq.sm} {
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: var(--font-weight-medium);
  }
`;
const NavLink = styled(Link)<NavLinkProps>`
  color: ${({ theme }) => (theme.screens.sm ? "var(--color-gray-500)" : "var(--color-text)")};
  text-decoration: none;
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
  transition: all 1s;

  &:hover {
    opacity: 0.7;
  }

  @media ${({ theme }) => theme.mq.sm} {
    color: ${({ isTransparent, transparentHeader }) =>
      isTransparent && transparentHeader ? "var(--color-gray-100)" : "var(--color-text)"};
    text-shadow: ${({ isTransparent, transparentHeader }) =>
      isTransparent && transparentHeader ? " 1px 1px 2px var(--color-gray-700)" : "none"};
  }
`;
const Hamburger = styled(motion.div)`
  display: ${({ theme }) => (theme.screens.sm ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-between;
  z-index: 1001;
  height: 24px;
  overflow: hidden;
  position: sticky;
`;
const HamburgerLine = styled(motion.div)<HamburgerLineProps>`
  height: 2px;
  background: ${({ isTransparent, transparentHeader, isOpen }) =>
    isTransparent && transparentHeader && !isOpen ? "var(--color-gray-100)" : "var(--color-text)"};
  width: 30px;
  border-radius: 50px;
`;
const Menu = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background: var(--color-footer-background);
  position: fixed;
  margin: 0;
  padding: 12rem 0rem 0rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  list-style: none;
  font-size: 2rem;
  overflow: hidden;
  z-index: 1000;

  .nStyle {
    fill: var(--color-primary);
  }
  .dubStyle {
    fill: var(--color-primary-light);
  }
`;

// Styles
const activeRoute = {
  paddingBottom: "0.1rem",
  borderBottom: "1px solid",
};

// Framer Motion Variants
const transparentHeaderVariants = {
  transparent: {},
  opaque: {
    transition: {
      duration: 2,
    },
  },
};

const hamburgerLineVariants = {
  showHamLineOne: { y: 11, rotate: -45 },
  showHamLineTwo: { x: "105%" },
  showHamLineThree: { y: -11, rotate: 45 },
};
const navItemVariants = {
  hidden: { opacity: 0, y: "100%" },
  show: { opacity: 1, y: 0 },
};

// Types
type SectionProps = {
  children: React.ReactNode;
  className?: string;
  backgroundColor?: string;
  minHeight?: string;
  paddingTop?: string;
  paddingBottom?: string;
  position?: string;
  isTransparent?: boolean | null;
  transparentHeader?: boolean | undefined;
};
type HeaderProps = {
  location: any;
};
type LogoProps = {
  isTransparent?: boolean | null;
  transparentHeader?: boolean | undefined;
};
type HamburgerLineProps = {
  isTransparent?: boolean | null;
  transparentHeader?: boolean | undefined;
  isOpen?: boolean | null;
};
type NavLinkProps = {
  isTransparent?: boolean | null;
  transparentHeader?: boolean | undefined;
};
export { Header };
